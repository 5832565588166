import { Component, OnInit } from '@angular/core';
import { fadeInAnimation } from '../animation';

@Component({
  selector: 'app-shows',
  templateUrl: './shows.component.html',
  styleUrls: ['./shows.component.css'],
  // make fade in animation available to this component
  animations: [fadeInAnimation],

  // attach the fade in animation to the host (root) element of this component
  host: { '[@fadeInAnimation]': '' }
})
export class ShowsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
