<div class="container">
    <div class="row">
        <div class="col-md-4" style="padding-bottom: 1rem;">
            <img src="assets/me.jpg" class="img-fluid">

        </div>

        <div class="col-md-8">
           
            <p>
            Dënalisa Shijaku ist eine in Berlin lebende Künstlerin mit albanischen Wurzeln, deren Werke eine beständige Suche nach Harmonie widerspiegeln. Ihre Kunst fungiert als Mittel, um Emotionen einzufangen und auf Leinwand festzuhalten. Jedes Bild lädt die Betrachter*innen ein, den schöpferischen Moment mitzuerleben – und kopfüber in eine unendliche Welt aus Farben, Formen und Materialien einzutauchen.
        </p>
        <p>    
        Dabei entspringt jeder Pinselstrich der engen Verbundenheit zwischen Künstlerin und Werk: „Ob Erinnerung, Alltagssituation oder Musik: meine Inspiration ruft spezifische Farbkombinationen hervor. Das, was in meinem Inneren brodelt, verlangt danach, sich Ausdruck zu verschaffen.“
    </p> <img src="assets/sign.jpg" class="img-fluid" style="height:15%;">

        </div>
    </div>
</div>