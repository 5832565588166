
<div class="container">
    <div *ngFor="let section of gallery" class="row">
        <div *ngFor="let sub of section | keyvalue" class="col-12">
            
            <div class="container">
                <!-- <div class="row">
                    <div class="col-md-12">
                        <p style="text-align: center; font-size: 3em; color: #b39696;;font-weight: 100;">{{sub.key}}</p>
                    </div>
                </div> -->
                <!-- <div class="row">
                    <div class="col-md-12" style="padding-bottom:0.5rem;">
                        <img src="assets/new_dena/dena_werke_nr.2.jpg" class="img-fluid">

                    </div>
                    <div class="col-md-12">
                        <img src="assets/titel.jpg" class="img-fluid">
                    </div>
                </div> -->
                <div class="row" style="padding-bottom: 5em;">
                    <div *ngFor="let image of sub.value" class="col-md-4" style="padding:0.5rem;">
                        <ngbd-modal-component [image] = image ></ngbd-modal-component>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>