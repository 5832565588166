import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngbd-modal-content',
  template: `
  <button type="button" class="close" style="outline: 0;margin:0.5rem;" aria-label="Close" (click)= "closeMe()">
  <span aria-hidden="true" style="font-family: Raleway,sans-serif;color:#654343;font-weight:light; font-size:1.5rem; ">X</span>
  </button>

    <img src={{art.large}} width="100%;" style="padding:1rem;" >
    <div style = "padding-left:1rem;padding-bottom:1rem;">
    <p style="background:transparent; padding:0rem; margin:0rem;font-weight:bold;">{{art.name}}</p>
    <p style="background:transparent; padding:0rem; margin:0rem;">{{art.material}} <br>{{art.size}}</p>
    <p style="background:transparent; padding:0rem; margin:0rem;margin-top:0.5rem;text-transform: uppercase; font-weight:500;">{{art.sold}}</p>
    <p style="background:transparent; padding:0rem; margin:0rem;">{{art.price}}</p>
    <p style="background:transparent; padding:0rem; margin:0rem;padding-top:1.5rem; font-size:1rem;">© Copyright 2021 </p>
    </div>
    `
})
export class NgbdModalContent {
  @Input() art:any;

  constructor(public activeModal: NgbActiveModal) {

  }

  public closeMe(){
    this.activeModal.close()
  }
}

@Component({
  selector: 'ngbd-modal-component',
  templateUrl: './modal-component.html',
  styleUrls: ['./modal-component.css']

})

export class NgbdModalComponent implements OnInit {
  @Input() image:any;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    console.log(this.image)
  }

  open() {
    console.log(this.image)

    const modalRef = this.modalService.open(NgbdModalContent);

    var dena = {path:this.image.large}
    modalRef.componentInstance.art = this.image;
  
  }
}
