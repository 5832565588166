import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ArtworkComponent } from './artwork/artwork.component';
import { MeComponent } from './me/me.component';
import { ComissionComponent } from './comission/comission.component';
import { ContactComponent } from './contact/contact.component';
import { ModalModule } from './modal/modal.module';
import { LandingComponent } from './landing/landing.component';
import { ShowsComponent } from './shows/shows.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ImpressumComponent } from './impressum/impressum.component';
import { ShopComponent } from './shop/shop.component';



@NgModule({
  declarations: [
    AppComponent,
    ArtworkComponent,
    ComissionComponent,
    MeComponent,
    ContactComponent,
    LandingComponent,
    ShowsComponent,
    ImpressumComponent,
    ShopComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ModalModule,
    AppRoutingModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
