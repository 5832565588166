import { Component, OnInit } from '@angular/core';
import { fadeInAnimation } from '../animation';

@Component({
  selector: 'app-comission',
  templateUrl: './comission.component.html',
  styleUrls: ['./comission.component.css'],
  // make fade in animation available to this component
  animations: [fadeInAnimation],

  // attach the fade in animation to the host (root) element of this component
  host: { '[@fadeInAnimation]': '' }
})
export class ComissionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  

}
