import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ArtworkComponent } from './artwork/artwork.component';
import { ComissionComponent } from './comission/comission.component';
import { ContactComponent } from './contact/contact.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { LandingComponent } from './landing/landing.component';
import { MeComponent } from './me/me.component';
import { ShopComponent } from './shop/shop.component';
import { ShowsComponent } from './shows/shows.component';

const routes: Routes = [
  {path: 'artwork', component: ArtworkComponent},
  {path: 'comission', component: ComissionComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'shows', component: ShowsComponent}, 
  {path: 'me', component: MeComponent},
  {path: 'impressum', component: ImpressumComponent},
  {path: 'shop', component: ShopComponent},

  {path: '**', component: LandingComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
