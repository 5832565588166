import { Component, OnInit } from '@angular/core';
import { fadeInAnimation } from '../animation';

@Component({
  selector: 'app-artwork',
  templateUrl: './artwork.component.html',
  styleUrls: ['./artwork.component.css'],
  // make fade in animation available to this component
  animations: [fadeInAnimation],

  // attach the fade in animation to the host (root) element of this component
  host: { '[@fadeInAnimation]': '' }
})
export class ArtworkComponent implements OnInit {
  gallery:any
  constructor() {

    this.gallery = [
      {
        "Abstrakte Kunst":
        [
          {
            small:  "assets/new/1.jpg",
            large:  "assets/new/1.jpg",
            // size: "180x140",
            // sold: "Verkauft",
            // material: "Mixed Media auf Leinwand",
            // name:"Will You Remember Me?"
          },
          {
            small:  "assets/new/2.jpg",
            large:  "assets/new/2.jpg",
            size: "280x160 cm",
            // material: "Mixed Media auf Leinwand",
            // name:"Flower Bouquet"
          },
          {
            small:  "assets/new/3.jpg",
            large:  "assets/new/3.jpg",
            // size: "90x126",
            // sold: "Verfügbar",
            // price:"550€",
            // material: "Mixed Media auf Leinwand",
            // name:"How Long"

          },
          {
            small:  "assets/new/4.jpg",
            large:  "assets/new/4.jpg",
            // size: "80x120",
            // sold: "Verkauft",
            // material: "Mixed Media auf Leinwand",
            // name:"Groovy kind of Love"
          },
          {
            small:  "assets/new/5.jpg",
            large:  "assets/new/5.jpg",
            // size: "280x160 cm",
            // material: "Mixed Media auf Leinwand",
            // name: "La Grande Bellezza"
          },
          {
            small:  "assets/new/6.jpg",
            large:  "assets/new/6.jpg",
            // size: "80x120",
            // sold: "Verkauft",
            // material: "Mixed Media auf Leinwand",
            // name:"Summer Rain"
          },
          // {
          //   small:  "assets/new/7.jpg",
          //   large:  "assets/new/7.jpg",
          // },
          // {
          //   small:  "assets/new/8.jpg",
          //   large:  "assets/new/8.jpg",
          //   size: "160x120 cm",
          //   material: "Mixed Media auf Leinwand",
          //   name: "Right Down The Line "
          // },
          // {
          //   small:  "assets/new/9.jpg",
          //   large:  "assets/new/9.jpg",
          // },
    //       {
    //         small:  "assets/Kunst/Abstrakt/15-abstrakte-werke-cut.jpg",
    //         large:  "assets/Kunst/Abstrakt/15-abstrakte-werke.jpg",
    //         size: "140x120",
    //         material: "Mixed Media auf Leinwand"
    //       },
    //       {
    //         small:  "assets/Kunst/Abstrakt/16-abstrakte-werke-cut.jpg",
    //         large:  "assets/Kunst/Abstrakt/16-abstrakte-werke.jpg",
    //         size: "120x240",
    //         material: "Mixed Media auf Leinwand",
    //         sold: "Auftragsarbeit",
    //       }
    //     ]
      
    //   },
    //   {
    //     "Color Thoughts":
    //     [
    //       {
    //         large:  "assets/Kunst/Color/1-color.jpg",
    //         small:  "assets/Kunst/Color/1-color-cut.jpg",
    //         size: "160x260",
    //         sold: "Privatbesitz",
    //         material: "Acryl auf Leinwand",
    //       },
    //       {
    //         large:  "assets/Kunst/Color/9-color.jpg",
    //         small:  "assets/Kunst/Color/9-color-cut.jpg",
    //         size: "260x180",
    //         sold: "Verkauft",
    //         material: "Acryl auf Leinwand ",
    //       },
    //       {
    //         large:  "assets/Kunst/Color/2-color.jpg",
    //         small:  "assets/Kunst/Color/2-color-cut.jpg",
    //         size: "180x140",
    //         sold: "Verfügbar",
    //         material: "Acryl auf Leinwand",
    //       },
    //       {
    //         large:  "assets/Kunst/Color/3-1-colour.jpg",
    //         small:  "assets/Kunst/Color/3-1-colour-cut.jpg",
    //         size: "100x120",
    //         sold: "Verkauft",
    //         material: "Acryl auf Leinwand",
    //       },
    //       {
    //         large:  "assets/Kunst/Color/3-color.jpg",
    //         small:  "assets/Kunst/Color/3-color-cut.jpg",
    //         size: "100x120",
    //         sold: "Verkauft",
    //         material: "Acryl auf Leinwand",
    //       },
    //       {
    //         large:  "assets/Kunst/Color/4-color.jpg",
    //         small:  "assets/Kunst/Color/4-color-cut.jpg",
    //         size: "130x110",
    //         sold: "Verkauft",
    //         material: "Acryl auf Leinwand",
    //       },
    //       {
    //         large:  "assets/Kunst/Color/6-color.jpg",
    //         small:  "assets/Kunst/Color/6-color-cut.jpg",
    //         size: "130x180",
    //         sold: "Verfügbar",
    //         price:"1050€",
    //         material: "Acryl auf Leinwand",
    //       },
    //       {
    //         large:  "assets/Kunst/Color/7-color.jpg",
    //         small:  "assets/Kunst/Color/7-color-cut.jpg",
    //         size: "120x140",
    //         sold: "Verfügbar",
    //         price:"800€",
    //         material: "Mixed Media auf Leinwand",
    //       },
    //       {
    //         large:  "assets/Kunst/Color/8-color.jpg",
    //         small:  "assets/Kunst/Color/8-color-cut.jpg",
    //         size: "120x140",
    //         sold: "Verfügbar",
    //         price:"800€",
    //         material: "Mixed Media auf Leinwand",
    //       }
    //     ]
    //   },
  
    //   {
    //     "Keramik":
    //   [
      
    //     {
    //       large:  "assets/Kunst/Keramik/1-keramik.jpg",
    //       small:  "assets/Kunst/Keramik/1-keramik-cut.jpg",
    //     }
      ]
     }
    ]
   }

  ngOnInit(): void {
  }


}
