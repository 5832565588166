
<div class="container">
  <div class="row" style="margin-bottom: 1em;">
    <div routerLink="/" routerLinkActive="active" class="col-12"
      style="text-align:center;font-family:Raleway; font-weight: 300; font-size: 3em;">
      <p style="margin-bottom: 0.01em;color: #b39696;">DËNALISA </p>
      <p style="margin-top: -0.4em;padding-top: 0em;color: #b39696;"> SHIJAKU</p>
    </div>
  </div>

  <div class="row" style="margin-bottom: -1em;border-bottom: 0.1rem solid #b39696;">
    <div class="col-md-12">
      <nav class="navbar navbar-expand-lg navbar-light" style="display: flex;">
        <!-- <a class="navbar-brand" [routerLink]="'.'">Responsive navbar</a> -->
        <!-- Step 3: Toggle the value of the property when the toggler button is clicked. -->
        <button class="navbar-toggler"
          style="justify-content: center;width: 100%; border: 0; font-size: 3rem;font-family: Raleway,sans-serif;color:transparent; text-shadow: 0 0 0 #b39696;font-weight:light;"
          type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
          <img src="assets/menu.svg" class="img-fluid" width="30rem;">
        </button>

        <!-- Step 2: Add the ngbCollapse directive to the element below. -->
        <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
          <ul class="navbar-nav justify-content-center" style="display: flex;">
            <li class="nav-item active">
              <!-- Step 4: Close the menu when a link is clicked. -->
              <a class="nav-link" [ngStyle]="{'font-weight':isbold[1]}" [routerLink]="'/artwork'"
                (click)="isMenuCollapsed = true">Werke</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [ngStyle]="{'font-weight':isbold[3]}" [routerLink]="'/comission'"
                (click)="isMenuCollapsed = true">Comissions/Auftragsarbeiten</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [ngStyle]="{'font-weight':isbold[5]}" [routerLink]="'/shows'"
                (click)="isMenuCollapsed = true">Ausstellungen</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [ngStyle]="{'font-weight':isbold[2]}" [routerLink]="'/me'"
                (click)="isMenuCollapsed = true">Über mich</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [ngStyle]="{'font-weight':isbold[4]}" [routerLink]="'/contact'"
                (click)="isMenuCollapsed = true">Kontakt</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [ngStyle]="{'font-weight':isbold[6]}" [routerLink]="'/shop'"
                (click)="isMenuCollapsed = true">Shop</a>
            </li>
          
          </ul>
        </div>
      </nav>
    </div>
  </div>

  <!-- <hr style="width:100%; height:0.1em; background-color: #b39696; border:none; padding-top: 0em; margin: 0em;" /> -->

  <div style="margin-top: 3rem; margin-bottom: 4rem;;">
    <div class="row">
      <div class="col-md-12">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>



  <div class="row" style="padding-bottom: 2rem;"> 
    <div class="col-md-12" style="text-align: center;">
      <a href="https://www.instagram.com/denalisa.shijaku">
        <img src="assets/instagram.svg" class="img-fluid" width="20rem;">
      </a>
    </div>
  </div>

  <div class="row bor" style=" padding-bottom: 2rem; padding-top: 0.5rem;">

    <div class="col-md-4" style="display: flex;justify-content: center">
      <p (click)="openModal()" style="font-size:1em;"> Datenschutzerklärung</p>
    </div>

    <div class="col-md-4" style="justify-content: center; display: flex;">
      <p style="background:transparent; font-size:1rem;">© Copyright 2021 </p>
    </div>
    <div class="col-md-4" style="display: flex;justify-content: center">
      <p (click)="openImpressum()" style="font-size:1em;"> Impressum</p>
    </div>

   
  </div>


</div>