import { animate, animateChild, group, query, style, transition, trigger } from "@angular/animations";

export const fadeInAnimation =
    // trigger name for attaching this animation to an element using the [@triggerName] syntax
    trigger('fadeInAnimation', [

        // route 'enter' transition
        transition(':enter', [

            // css styles at start of transition
            style({ opacity: 0 }),

            // animation and styles at end of transition
            animate('.8s', style({ opacity: 1 }))
        ]),
    ]);