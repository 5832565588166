import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {  fadeInAnimation } from './animation';
import { DataProtectionContent } from './app-dataprotect';
import { ImpressumContent } from './app-impressum';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    fadeInAnimation
    // animation triggers go here
  ]
})
export class AppComponent {
  title = 'dena';
  isbold = { '1': 'normal', '2': 'normal', '3': 'normal', '4': 'normal', '5': 'normal','6': 'normal' }

  public isMenuCollapsed = true;

  constructor(private metaTagService: Meta,router: Router, private modalService: NgbModal) {
    router.events.subscribe((url: any) => this.name(url.url));
  }

  ngOnInit(){
    this.metaTagService.addTags([
      {name:'keywords',content:'German painter with Albanian roots.'},
      {name:'description',content:"Dënalisa strebt nach Harmonie durch die abstrakte Anordnung und Neu-Anordnung von Farbe, Materialien, Formen und Geschichten."},
      {name:'author',content:'Dënalisa Shijaku'},
      {name:'date',content:'2020-04-01', scheme:'YYYY-MM-DD'},
      {name:'date',content:'UTF-8'},

    ])
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
  
  openModal() {

    const modalRef = this.modalService.open(DataProtectionContent);
    //modalRef.componentInstance.art = this.image;
  
  }
  openImpressum() {

    const modalRef = this.modalService.open(ImpressumContent);
    //modalRef.componentInstance.art = this.image;
  
  }

  private setNormal(){
    for (let key in this.isbold) {
      this.isbold[key] = 'normal'
    }
  }

  private name(path: string) {

    if(typeof(path)!= 'string'){
      return;
    }
    this.setNormal()
    switch (path) {
      case '/artwork':
        this.isbold['1'] = 'bold'
        break;

      case '/me':
        this.isbold['2'] = 'bold'
        break;

      case '/comission':
        this.isbold['3'] = 'bold'
        break;

      case '/contact':
        this.isbold['4'] = 'bold'
        break;

      case '/shows':
        this.isbold['5'] = 'bold'
        break;
      
      case '/shop':
        this.isbold['6'] = 'bold'
        break;

      default:
        break;
    }


  }
}
