<div class="container">
    <div class="row">
        <div class="col-md-12" style="padding-bottom: 1rem; text-align: center;">


            <p style = "color: #654343;font-weight:500;">
                Please reach out on:
            </p>
            
                
                
                <p>
                    <a href="https://www.instagram.com/denalisa.shijaku" style = "color: #654343;" >@denalisa.shijaku</a>
    
                    <br>
                    <a href="mailto:denalisa.shijaku@gmail.com"style = "color: #654343;" >denalisa.shijaku@gmail.com</a>
                </p>



        </div>
    </div>
</div>