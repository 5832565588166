<div class="container">
    <div class="row">
        <div class="col-md-4">
            <img src="assets/new_dena/Dena_vorderseite_nr.1jpg.jpg" class="img-fluid">
        </div>
        <div class="col-md-4">
            <img src="assets/new_dena/Dena_vorderseite_nr.2.jpg" class="img-fluid">
        </div>
        <div class="col-md-4">
            <img src="assets/new_dena/Dena_vorderseite_nr3.jpg" class="img-fluid">
        </div>
    </div>


</div>

