<div class="container">
    <div class="row">


        <div class="col-md-4" style="padding-bottom: 1rem;">
            <img src="assets/Auftragsarbeit.jpg" class="img-fluid">

        </div>
        <div class="col-md-8">

            <p> Ein Kunstwerk für Sie. Für einen ganz bestimmten Ort. 
                Passend zu einer ganz bestimmten Umgebung.  </p>
            
            <p> 
                
                Auf Anfrage arbeite ich gerne in einem von Ihnen gewünschten Format oder 
                Farbspektrum. Auf Basis eines persönlichen Besuchs (oder Video-Calls) können 
                wir Größe, Platzierung und Farben ihres zukünftigen Kunstwerks 
                gemeinsam besprechen. 
            </p>
            <p>
                Für Auftragsarbeiten wird ein Festpreis vereinbart. <br> 
            </p>
            <p>
                Bitte kontaktieren Sie mich per Email oder via Instagram.
            </p>

            <p>
                <a href="https://www.instagram.com/denalisa.shijaku" style = "color: #654343;" >@denalisa.shijaku</a>

                <br>
                <a href="mailto:denalisa.shijaku@gmail.com"style = "color: #654343;" >denalisa.shijaku@gmail.com</a>
            </p>
        </div>
    </div>
</div>