<div class="container">
    <div class="row">
        <div class="col-md-4">
            <img src="assets/kontakt.jpg" class="img-fluid">

        </div>

        <div class="col-md-8" style="text-align: left; margin-top: 10rem;">
            <p>
                <a href="https://www.instagram.com/denalisa.shijaku" style = "color: #654343;" >@denalisa.shijaku</a>

                <br>
                <a href="mailto:denalisa.shijaku@gmail.com"style = "color: #654343;" >denalisa.shijaku@gmail.com</a>
            </p>
            <p>
                <a style = "color: #654343;" >Foto Credits Viola Patzig</a>

                <br>
                <a href="https://instagram.com/violapatzig?igshid=YmMyMTA2M2Y=" style = "color: #654343;" >@violapatzig</a>
            </p>

        </div>


    </div>
</div>