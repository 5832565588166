<div class="container">

    <!-- <div class="row"
        style=" border-bottom-style: solid; border-bottom-width: 0.02rem; margin-bottom: 2rem; border-bottom-color: #654343;">
        <div class="col-md-4"
            style="vertical-align: middle;display: inline-block;justify-content: center;padding-bottom: 1rem;">
            <p style="margin-bottom: 0rem;">Einzelausstellung 01.10 - 30.11.2022 </p>
            <p style="margin-bottom: 0rem;"> Prenzlauer Berg</p>
            <p>Berlin</p>

        </div>

        <div class="col-md-4">
            <img src="assets/show1.jpg" class="img-fluid" style="padding-bottom: 1rem;">

        </div>
        <div class="col-md-4">
            <img src="assets/show2.jpg" class="img-fluid" style="padding-bottom: 1rem;">
        </div> 
    </div> -->
    <div class="row">
        <div class="col-md-4"
            style="vertical-align: middle;display: inline-block;justify-content: center;padding-bottom: 1rem;">

            <p style="margin-bottom: 0rem;">NUO Gallery</p>


            <p style="margin-bottom: 0rem;">April 2023 - September 2023</p>
            <p style="margin-bottom: 0rem;">Hans-Sachs-Straße 6a</p>
            <p>80469 München</p>
            <hr>

        </div>

    </div>




    <div class="row">
        <div class="col-md-4"
            style="vertical-align: middle;display: inline-block;justify-content: center;padding-bottom: 1rem;">
            <p style="margin-bottom: 0rem;">Group Exhibition</p>

            <p style="margin-bottom: 0rem;">COME TOGETHER</p>


            <p style="margin-bottom: 0rem;">25.03.2023 | 18 Uhr</p>
            <p style="margin-bottom: 0rem;">Greifswalder Str. 34-35</p>
            <p>Berlin</p>

            <p style="font-size:large;margin-bottom: 0rem;">Dënalisa Shijaku</p>
            <p style="font-size:large; margin-bottom: 0rem;">Bings</p>
            <p style="font-size:large;margin-bottom: 0rem;">Elisa Klinkenberg</p>
            <p style="font-size:large;margin-bottom: 0rem;">Jonathan Esperester</p>
            <p style="font-size:large;margin-bottom: 0rem;">Tanja Neubert</p>
            <p style="font-size:large;margin-bottom: 0rem;">Janine Kuehn</p>
            <p style="font-size:large;margin-bottom: 0rem;">Viola Patzig</p>
            <p style="font-size:large;margin-bottom: 0rem;">Oni Studio</p>
            <hr>

        </div>
        <!-- <div class="col-md-8">
            <img src="assets/show4.jpg" class="img-fluid" style="padding-bottom: 1rem;">

        </div> -->
    </div>

    <div class="row">
        <div class="col-md-4"
            style="vertical-align: middle;display: inline-block;justify-content: center;padding-bottom: 1rem;">
            <p style="margin-bottom: 0rem;">Come Closer </p>
            <p style="margin-bottom: 0rem;">18.03.2023 - 24.03.2023</p>
            <p style="margin-bottom: 0rem;">Greifswalder Str. 34-35</p>
            <p>Berlin</p>
            <p style="font-size:large;margin-bottom: 0rem;"> Dënalisa Shijaku</p>

            <hr>

        </div>
        <!-- <div class="col-md-8">
            <img src="assets/show3.jpg" class="img-fluid" style="padding-bottom: 1rem;">

        </div> -->
    </div>
    <div class="row">
        <div class="col-md-4"
            style="vertical-align: middle;display: inline-block;justify-content: center;padding-bottom: 1rem;">
            <p style="margin-bottom: 0rem;">Show Off Exhibition 20.02.2020 </p>
            <p style="margin-bottom: 0rem;"> Prenzlauer Berg</p>
            <p>Berlin</p>

            <p style="font-size:large; margin-bottom: 0rem;">Katharina Ruhm </p>
            <p style="font-size:large;margin-bottom: 0rem;">Jule Waibel</p>
            <p style="font-size:large;margin-bottom: 0rem;">Nektarios Dallas</p>
            <p style="font-size:large;margin-bottom: 0rem;">Dënalisa Shijaku</p>
            <hr>

        </div>
        <!-- <div class="col-md-4">
            <img src="assets/show1.jpg" class="img-fluid" style="padding-bottom: 1rem;">

        </div>
        <div class="col-md-4">
            <img src="assets/show2.jpg" class="img-fluid" style="padding-bottom: 1rem;">
        </div> -->
    </div>

    
</div>